.partner-common-container {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.partner-header {
  .partner-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 4%;
  }

  a {
    background-size: 662px;
  }
}

.biznes-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    p {
      margin-top: 20px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #444444;
    }
  }

  .arrow {
    margin-top: 3%;
  }
}

.site-container {
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .partner-header .partner-title {
    font-size: 36px;
    line-height: 50px;
  }

  .biznes-container {
    div {
      svg {
        width: 50%;
      }
    }
  }

  .biznes-container .arrow {
    margin-top: 6%;
  }

  .biznes-container div p {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
  }

  .site-container {
    img {
      width: 80%;
    }
  }
}

@media (max-width: 767px) {
  .partner-header .partner-title {
    font-size: 28px;
    line-height: 42px;
  }
  .partner-header {
    a {
      font-size: 18px;
    }
  }

  .partner-common-container {
    .biznes-container .arrow {
      margin-top: 8%;
    }
    .biznes-container div p {
      font-size: 12px;
    }

    div {
      h2 {
        font-size: 24px !important;
      }
    }
  }
}

@media (max-width: 577px) {
  .partner-header .partner-title {
    font-size: 24px;
    line-height: 40px;
  }
  .partner-header a {
    font-size: 16px;
  }
  .partner-common-container div h2 {
    margin: 0 auto;
    max-width: 340px;
  }

  .biznes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .biznes-container div svg {
    width: 85%;
  }

  .arrow {
    margin-top: 5%;
    margin-bottom: 5%;
    svg {
      transform: rotate(90deg);
    }
  }

  .partner-common-container .biznes-container div p {
    font-size: 18px;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
}

@media (max-width: 420px) {
  .partner-common-container div h2 {
    font-size: 20px !important;
    line-height: 28px;
  }
  .partner-header .partner-title {
    font-size: 20px;
    line-height: 35px;
  }
}

@media (max-width: 360px) {
  .partner-header .partner-title {
    font-size: 18px;
    line-height: 34px;
  }
  .partner-header a {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .partner-common-container .biznes-container div p {
    font-size: 14px;
    margin-top: 15px;
  }
  .partner-header .partner-title {
    font-size: 17px;
  }
}

@media (max-width: 280px) {
  .partner-header .partner-title {
    font-size: 17px;
    line-height: 30px;
  }
}
