$base-color: #a8cf45;
// common containers
.makeGreen {
  color: $base-color !important;
}

.server-errors {
  p {
    color: #f70c55;
    padding: 0;
    margin: 0;
    margin-bottom: 2%;
  }
}

.background-container {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background: url("../images/login-back.webp");
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.white-container {
  height: 600px;
  width: 500px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
}

.top-dark-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #393230;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 50px;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;

  .link-to-home-container {
    display: grid;
    grid-template-columns: 250px auto;
    justify-content: center;
    align-items: space-between;
    p {
      margin: 0;
      padding: 0;
      color: $base-color;
    }
  }
  .change-lang-container {
    display: grid;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      span {
        cursor: pointer;
      }
    }
  }
}
// common containers end

.form-title {
  text-align: center;
  font-family: Inter, sans-serif;
  color: $base-color;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

label {
  margin-top: 15px;
  margin-bottom: 5px;
  padding-bottom: 0;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #777;
}
input {
  height: 40px;
  background: #fff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 15px;
  outline-color: #b7b7b7;
}

button {
  height: 50px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

// login container start
.login-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto 190px;
  align-content: space-between;
  div {
    display: flex;
    flex-direction: column;
  }

  .login-show-password-span-container {
    width: 100%;
    position: relative;
    .login-show-password-icon {
      position: absolute;
      right: 10px;
      top: 25%;
      color: #777;
    }
  }
  .login-btn-container {
    display: grid;
    align-content: space-between;
    // border: 2px solid black;
    .login-btn {
      background-color: $base-color;
      color: #fff;
    }
    .register-route-btn {
      background-color: #efefef;
      color: #273142;
    }
    .forget-password-route-btn {
      padding-bottom: 5%;
      box-sizing: border-box;
    }
  }
}
// login container end

// register container start
.register-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: space-between;
  div {
    display: flex;
    flex-direction: column;
  }
  .register-show-password-span-container {
    width: 100%;
    position: relative;
    .register-show-password-icon {
      position: absolute;
      right: 10px;
      top: 25%;
      color: #777;
    }
  }

  .user-type-container {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: auto;
    justify-content: space-between;
    label {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 40px;
      margin: 0;
      padding: 0;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #444;
      cursor: pointer;
    }
  }
  .register-btn {
    background-color: $base-color;
    color: #fff;
  }
}

// register container end

//sendCode container start
.sendCode-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    .sendCode-btn {
      background-color: $base-color;
      color: #fff;
    }
  }
}
//sendCode container end

//confirmCode container start
.confirm-code-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto 100px;
  align-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15%;
    }
  }
  .confirm-code-btns {
    display: grid;
    // height: 100%;
    align-content: space-between;
    .confirm-code-btn {
      background-color: $base-color;
      color: #fff;
    }
  }
}

//confirmCode container end

//forget password container start

.forgot-password-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto 100px;
  align-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    .forgot-password-btn {
      background-color: $base-color;
      color: #fff;
    }
  }
}

.forgot-password-try-again-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: space-between;
  justify-content: space-between;
  .forgot-password-try-again-content {
    margin-top: 15%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 150px auto;
    p {
      font-size: 24px;
      line-height: normal;
    }
    button {
      background-color: $base-color;
      color: #fff;
      width: 100%;
    }
  }
  p {
    text-align: center;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

//forget password container end

// auth complete container start
.auth-complete-container {
  height: 100%;
  width: 100%;
  display: grid;
  padding: 8%;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-content: center;
  justify-content: center;

  p {
    font-size: 24px;
    line-height: normal;
    text-align: center;
  }
  button {
    background-color: $base-color;
    color: #fff;
    width: 100%;
  }
}
// auth complete container end

// media queries

@media (max-width: 1440px) {
  .background-container {
    height: 125vh;
  }
}

@media (max-width: 1280px) {
  .background-container {
    height: 120vh;
  }
}

@media (max-width: 1024px) {
  .top-dark-container .link-to-home-container {
    grid-template-columns: auto;
    p {
      margin-bottom: 2px;
    }
  }
  .background-container {
    height: 110vh;
  }
  .white-container {
    height: 490px;
    width: 490px;
  }

  .form-title {
    font-size: 24px;
    line-height: normal;
  }

  input {
    height: 35px;
  }
  button {
    height: 40px;
  }

  .login-container {
    grid-template-rows: auto 165px;
  }

  .confirm-code-container div .counter {
    margin-top: 8%;
  }

  .register-container .user-type-container label {
    height: 35px;
    font-size: 16px;
  }

  .register-container {
    div {
      label {
        margin-top: 5px;
      }
    }
  }
}
@media (max-width: 768px) {
  .top-dark-container .link-to-home-container {
    grid-template-columns: auto;
    p {
      margin-bottom: 2px;
    }
  }

  .background-container {
    height: 100vh;
  }
  .white-container {
    height: 450px;
    width: 450px;
  }
  .form-title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0;
  }

  label {
    margin-top: 5px;
    font-size: 12px;
  }
  input {
    height: 30px;
    font-size: 12px;
  }

  button {
    height: 35px;
  }
  .server-errors p {
    font-size: 12px;
  }
  .srv-validation-message {
    font-size: 12px;
  }

  .login-container {
    grid-template-rows: auto 145px;
  }

  .confirm-code-container div .counter {
    margin-top: 6%;
  }
  .register-container .user-type-container label {
    height: 30px;
    font-size: 12px;
  }
  .auth-complete-container p {
    font-size: 22px;
  }

  .forgot-password-try-again-container .forgot-password-try-again-content p {
    font-size: 22px;
  }
}

@media (max-width: 640px) {
  .top-dark-container .link-to-home-container p {
    font-size: 10px;
  }

  .top-dark-container .change-lang-container p {
    font-size: 10px;
  }

  .white-container {
    height: 440px;
    width: 280px;
  }
  .form-title {
    font-size: 18px;
  }

  button {
    font-size: 12px;
    line-height: normal;
  }

  .login-container {
    grid-template-rows: auto 145px;
  }

  .confirm-code-container div .counter {
    margin-top: 15%;
  }

  .confirm-code-container {
    grid-template-rows: auto 70px;
  }

  .auth-complete-container p {
    font-size: 18px;
  }
  .forgot-password-try-again-container .forgot-password-try-again-content p {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .white-container {
    width: 265px;
  }
}
