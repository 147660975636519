// step bar

.m-step-bar {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  div {
    width: 140px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #8b8b8b;
    margin-left: 3px;
  }
}

.step-round {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8b8b8b;
  width: 30px;
  height: 30px;
  background-color: #d2d2d2;
  border-radius: 50%;
  margin-right: 5%;
}

////////////////////////////
// login-topbar
.login-topbar {
  background-color: #393230;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  div {
    ul {
      .go-home {
        cursor: pointer;
      }
      li {
        color: #948e8c;
        font-size: 14px;
        padding-right: 25px;
        display: inline-block;
      }
      i {
        padding-right: 10px;
      }
    }
  }
}

////////////////////////////

.m-login-container {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 554px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;

  h3 {
    text-align: center;
    margin: 15px;
    font-family: Inter, sans-serif;
    color: #00a075;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  }

  form {
    width: 100%;
    max-width: 454px;
    display: flex;
    flex-direction: column;

    label {
      margin-top: 10px;

      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #777777;
    }

    input[type="text"],
    input[type="password"] {
      margin-bottom: 0px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 5px;
      padding-left: 15px;
      // outline-color: #00a075;
      outline-color: #b7b7b7;
    }

    input[type="password"]::placeholder {
      color: #d2d2d2;
    }

    input[type="text"]::placeholder {
      color: #d2d2d2;
    }

    div.divver {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      label {
        margin-left: 2%;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    button {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 4px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
    .m-login-btn {
      background-color: #00a075;
      color: #ffffff;
    }

    .m-signUp-btn {
      color: #273142;
    }
    .m-password-btn {
      background-color: #ffffff !important;
      color: #777777;
    }
  }
}

.choose-user {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;

  .user-radio-container {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;

    .user-radio-btn {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 40px;
      width: 48%;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      input[type="radio"] {
        cursor: pointer;
      }
      label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 22px !important;
        color: #444444 !important;
        cursor: pointer;
      }
    }
  }
}

//code come
.enter-code-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23.5px;
  line-height: 29px;
  color: #444444;
  margin-bottom: 28px;
  margin-top: 5px;
}

.code-enter-time {
  text-align: right;
}

.empty-container {
  height: 250px;
}

.confirm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 380px;
  padding: 40px;

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 156.5%;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #444444;
    margin-bottom: 40px;
  }
  button {
    // width: 120px;
    height: 50px;
    background: #00a075;
    border-radius: 4px;
    color: #ffffff;
    font-size: 18px;
    outline: none;
    border: none;
  }
}

// seller register

.select-btn {
  background: #fbfcfd;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 5px;
  height: 51px;
  padding-left: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #858a96;
  margin-bottom: 15px;
}

.img-uploader {
  height: 141px;
  width: 123px;
  background: #fbfcfd;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.upload-img-btn {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 11;
}

.img-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  opacity: 0;
}

.after-img {
  opacity: 1 !important;
}

.delete-btn {
  position: absolute;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  top: 0px;
  right: 5px;
  background-color: red;
  z-index: 13;
  i {
    color: white;
  }
}

.email-or-phone-container {
  box-sizing: border-box;
  // padding-top: 20px;
  padding-bottom: 40px;
  width: 554px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
}
.email-or-phone-tab {
  width: 100%;
  .react-tabs__tab-list {
    border-bottom: none;
    box-sizing: border-box !important;
  }

  .react-tabs__tab {
    width: 50%;
    height: 60px;
    box-sizing: border-box !important;
    text-align: center;
    color: #1010104d !important;
    padding-top: 15px;
    border-radius: 5px !important;
    border: none !important;
    font-weight: 500;
    font-size: 18px;
  }

  .react-tabs__tab--selected {
    box-sizing: border-box !important;
    background-color: #efefef !important;
    color: #00a075 !important;
    border-radius: 5px !important;
    border: none !important;
  }

  .react-tabs__tab:focus:after {
    content: none !important;
  }
  .email-or-phone-form {
    padding: 5%;
    padding-top: 10%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50px auto auto 200px auto;
    input {
      margin-bottom: 0px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 5px;
      padding-left: 15px;
      outline-color: #b7b7b7;
    }
    button {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 4px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
}

@media (max-width: 1430px) {
  .m-login-container {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 500px;
  }
  .m-login-container h3 {
    font-size: 25px;
    line-height: 36px;
  }
  .m-login-container form input[type="text"],
  .m-login-container form input[type="password"] {
    margin-bottom: 0px;
    height: 40px;
  }

  .m-login-container form button {
    height: 50px;
  }

  .select-btn {
    height: 40px;
  }
}

@media (max-width: 645px) {
  .m-login-container {
    padding-right: 35px;
    padding-left: 35px;
    width: 410px;
  }

  .confirm-container {
    height: 400px;
    p {
      font-size: 16px;
    }
    button {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }
  }
  .m-step-bar {
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    div {
      width: 100px;
      font-size: 12px;
      .step-round {
        width: 22px;
        height: 22px;
      }
      p {
        font-size: 10px;
      }
    }

    svg {
      width: 14px;
      margin-bottom: 3%;
    }
  }
  .enter-code-title {
    font-size: 17.4px;
    margin-bottom: 15px;
  }
  .empty-container {
    height: 100px;
  }
  .img-uploader {
    height: 120px;
    margin-left: 5%;
  }
}

@media (max-width: 577px) {
  .m-login-container {
    width: 87%;

    h3 {
      font-size: 18px;
      margin: 10px;
    }

    form {
      input[type="text"],
      input[type="password"] {
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
      }

      label {
        margin-left: 5%;
      }

      div.divver {
        margin-left: 5%;
      }
      button {
        margin: 0 auto;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 90%;
        height: 40px;
      }
    }
  }
  .choose-user {
    margin-left: 0 !important;
    p {
      margin-left: 5%;
    }
    .user-radio-container {
      width: 95%;
      margin-left: 0 !important;
    }
  }
  .choose-user .user-radio-container .user-radio-btn label {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .enter-code-title {
    font-size: 13.6px;
    margin-bottom: 6px;
  }
  .code-enter-time {
    margin-right: 5%;
  }
  .select-btn {
    height: 36px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
  }
  .confirm-container {
    height: 350px;
  }

  .m-step-bar {
    div {
      font-size: 10px;
      margin-left: 5px;
      .step-round {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: 8px !important;
      }
    }
  }
}

@media (max-width: 420px) {
  .m-image-container {
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .m-login-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .m-login-container {
    h3 {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .m-step-bar {
    margin-left: 5%;
  }
}

@media (max-width: 320px) {
  .choose-user .user-radio-container .user-radio-btn label {
    font-size: 12px !important;
    line-height: 22px !important;
  }
  .m-login-container {
    h3 {
      font-size: 14px;
      line-height: 22px;
    }
    form {
      input[type="text"],
      input[type="password"] {
        font-size: 12px;
      }
      button {
        font-size: 12px;
      }
    }
  }
  .enter-code-title {
    font-size: 11.5px;
    line-height: 21px;
  }
  .m-step-bar {
    margin-left: 0;
    margin-top: 10px;
    justify-content: space-evenly;
    align-items: center;
    div {
      width: auto;
      p {
        display: none;
      }
    }
    svg {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 280px) {
  .confirm-container {
    padding: 0;
  }
  .choose-user .user-radio-container .user-radio-btn label {
    font-size: 9px !important;
    line-height: 22px !important;
  }
  .choose-user .user-radio-container .user-radio-btn {
    height: 36px;
  }
  .m-login-container {
    h3 {
      font-size: 12px;
      line-height: 22px;
    }

    form {
      label {
        font-size: 11px;
      }
      input[type="text"],
      input[type="password"] {
        font-size: 10px;
        margin-bottom: 5px;
      }
      button {
        font-size: 12px;
      }
    }
  }
  .choose-user {
    p {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 11px;
    }
  }
  .enter-code-title {
    font-size: 8.8px;
  }
  .empty-container {
    height: 50px;
  }
  .confirm-container {
    height: 310px;
  }
  .select-btn {
    font-size: 10px;
  }
}

// multi-selection

.basic-multi-select {
  margin-left: 0%;
  margin-right: 0%;
}

.select__control {
  * {
    max-height: 40px;
  }
}

.select__value-container {
  display: flex;
  align-items: center;
  position: unset !important;
}

// forget password
.forget-password-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    color: #00a075;
    font-family: Inter, sans-serif;
    font-size: 24px;
  }
  button {
    margin: 0 auto !important;
    width: 150px !important;
  }
  p {
    margin: 15px;
    line-height: 20px;
    margin-top: 20px;
  }

  #sh-message {
    margin-bottom: 40px;
    margin-top: 60px;
    font-size: 18px;
  }

  #sh-ques {
    margin-top: 110px;
  }

  .close-reset-password-btn {
    background-color: #00a075;
    color: #ffffff;
  }
  .not-get-email {
    cursor: pointer;
    color: #777;
    .resend-btn {
      text-decoration: underline;
      color: #00a075;
      margin-left: 5px;
    }
  }
}

.not-found-user {
  display: block !important;
  color: red !important;
}

.outline-error {
  border: red 2px solid !important;
}

.activate-btn {
  background-color: #00a075 !important;
  color: #ffffff !important;
}
.not-activate-btn {
  background-color: #efefef !important;
  color: #1010104d;
}

.wrong-code-msg {
  color: red !important;
}

.code-counter {
  margin: 0 auto;
  margin-top: 10% !important;
  margin-bottom: 20% !important;
}

.showPasswordSpan {
  position: relative;
  input {
    width: 100%;
  }
  .showPasswordIco {
    position: absolute;
    top: 25%;
    right: 3%;
    color: #777;
  }
}
