.shop-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
  line-height: 1 !important;
}

.contact-container {
  width: 23%;
  height: 472px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  .contact-title {
    padding: 10px;
    padding-left: 25px;
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: unset;
    background: #a8cf45;
    color: #ffffff;
    height: 50px;
  }

  .contact-p {
    margin-top: 24px;
    margin-left: 9%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }

  ul {
    display: flex;
    flex-direction: column;
    li {
      margin-left: 9%;
      margin-right: 9%;
      display: flex;
      justify-content: flex-start;
      p {
        margin-left: 8px;
        i {
          margin-right: 8px;
        }
      }
      a {
        margin-left: 8px;
        i {
          margin-right: 3px;
        }
      }
    }
  }
}

// comment about Company

.company-info {
  width: 75%;
}

.about-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #444444;
}

.add-comment-btn {
  background: #a8cf45;
  height: 40px;
  width: 177px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.commentComp {
  margin-left: 0 !important;
  margin-top: 20px;
}

.company-description {
  padding: 25px 25px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #777777;
  }
}

// star
.store-review-container {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    textarea {
      width: 100%;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 5px;
      outline-color: #a8cf45;
      margin-bottom: 10px;
    }
  }
  .store-star-rating {
    font-size: 24px;
  }
}

// pagination
.btn-pagination,
.page-pagination {
  a {
    color: #666;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 3px;
    margin: 0 3px;
    background-color: white;
    border: 1px solid #cfcfcf;
    cursor: pointer;
  }
}

.active-pagination {
  a {
    color: #ffffff;
    background-color: #a8cf45;
  }
}
///////
.store-comment-fluid {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin: 10px 0;
  margin-bottom: 15px;
  .comment-author {
    display: flex;
    justify-content: space-between;
    .author-info {
      display: flex;
      p {
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: 1430px) {
}
@media (max-width: 1430px) {
}
@media (max-width: 1430px) and (min-width: 1368px) {
}

@media (max-width: 1430px) and (min-width: 1200px) {
}
@media (max-width: 1367px) {
}
@media (max-width: 1199px) {
  .shop-container {
    .contact-container {
      width: 30%;
    }
    .company-info {
      width: 65%;
    }
  }
}
@media (max-width: 991px) and (min-width: 767px) {
}

@media (max-width: 991px) {
  .shop-container {
    .contact-container {
      width: 35%;
    }
    .company-info {
      width: 60%;
    }
  }
}
@media (max-width: 767px) {
  .shop-container {
    flex-direction: column-reverse;
    .contact-container {
      width: 100%;
    }
    .company-info {
      width: 100%;
      margin-bottom: 10%;
    }
  }
}
@media (max-width: 577px) {
}
@media (min-width: 577px) {
}
@media (max-width: 480px) {
}
@media (max-width: 420px) {
  .store-comment-fluid .comment-author .author-info {
    flex-direction: column;
    p:nth-child(1) {
      line-height: 0;
      margin-bottom: 4px;
    }
  }
  .company-description p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 360px) {
}
@media (max-width: 320px) {
}

@media (max-width: 280px) {
  .shop-container {
    .pagination {
      font-size: 6px;
    }
  }
  .store-comment-fluid .comment-author .author-info {
    p {
      font-size: 11px;
    }
  }
  .company-description p {
    font-size: 12px;
    line-height: 18px;
  }
}
