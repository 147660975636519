.user-profile-contanier {
  display: flex;
  padding-top: 40px;

  .u-profile-sidebar {
    // max-width: 300px;
    min-width: 290px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 15px;
    // background-color: #c4fcef;
    background-color: rgba(241, 241, 241, 0.5);
    border-radius: 5px;
    height: 50vh;
    button {
      width: 100%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 14px;
      color: #444444;
      outline: none;
      background-color: rgba(241, 241, 241, 0);
      border: none;
      text-align: left;
      padding: 10px;

      .feather-icons {
        margin-right: 8px;
      }
    }
  }

  .u-profile-page {
    width: 100%;
    height: auto;
    // box-shadow: 0px 4px 8px rgba(97, 97, 97, 0.18);
    // border-radius: 5px;
    margin: 20px;
    margin-top: 0;
    padding-bottom: 15px;
  }
}

// main profile

.profile-sidebar-title {
  color: #333;
  margin: 20px;
  font-size: 24px;
  line-height: 1.4;
  margin-top: 0;
  margin-left: 10px;
}

.main-profile-container {
  display: flex;
  flex-direction: column;

  .fixed-user-info {
    display: flex;
    // justify-content: space-between;
    // padding: 20px;
    padding-left: 10px;
  }

  .user-avatar {
    min-width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(241, 241, 241, 0.5);
    border-radius: 5px;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
    div {
      text-align: center;
      padding: 15px;
      border-radius: 5px;
      .user-name {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #00a075;
      }
      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        color: #444444;
        margin: 0;
      }
    }
  }

  .user-data {
    margin-left: 35px;
    margin-right: 0px;

    table {
      min-width: 650px;
      tr {
        height: 55px;

        td {
          padding-left: 15px;
        }
        td:nth-child(odd) {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #444444;
          padding-right: 10px;
        }
        td:nth-child(even) {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #777777;
          padding-right: 10px;
        }
      }
      tr:nth-child(odd) {
        background-color: rgba(241, 241, 241, 0.5);
      }
    }
  }
}

// edit user profile

.edit-profile-container {
  .avatar-error-img {
    margin-top: 10px;
    margin-left: 5%;
    color: red;
  }
  .edit-feather-icons {
    margin-right: 8px;
  }

  .edit-user-awatar {
    margin: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(241, 241, 241, 0.5);
    border-radius: 5px;

    .user-edit-img {
      position: relative;
      width: 152px;
      height: 152px;
      border-radius: 50%;
      overflow: hidden;

      .edit-avatar-upload-label {
        position: absolute;
        margin: 0;
        bottom: 0;
        left: 0%;
        height: 33%;
        width: 152px;
        border-radius: 0 0 90px 90px;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .edit-avatar-upload {
        //display: none;
        position: absolute;
        bottom: 30px;
        left: 30px;
        opacity: 0;
      }
    }

    img {
      width: 152px;
      height: 152px;
    }
    div {
      margin-left: 25px;
      .edit-user-name {
        font-family: Inter, sans-serif;
        color: #00a075;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 5px;
      }
      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #444444;
        margin-bottom: 0;
      }
    }
  }

  button {
    width: 120px;
    height: 40px;
    background: #00a075;
    border: 1px solid #00a075;
    box-sizing: border-box;
    border-radius: 10px;
    color: #ffffff;
    float: right;
    margin-top: 20px;
    margin-right: 3%;
    font-size: 14px;
  }
}

.edit-profile-form {
  //border: 2px solid black;
  display: flex;
  justify-content: space-evenly;
  margin-right: 20px;

  div {
    width: 50%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    label {
      margin-top: 10px;
      margin-bottom: 5px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
    input,
    select {
      padding-left: 15px;
      height: 35px;
      border: 1px solid #d7dae0;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 5px;
      color: #777777;
      font-size: 12px;
      outline-color: #00a075;
    }
  }
}

//my cooments
.comment-container {
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(97, 97, 97, 0.18);
  border-radius: 5px;
  // box-shadow: 0px 3.78815px 37.8815px rgba(0, 0, 0, 0.05);
  .comment-info {
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      p {
        margin-left: 5px;
        margin-right: 15px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 18px !important;

        color: #777777 !important;
      }
    }
  }
  .comment-text {
    p {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px !important;
      line-height: 18px !important;

      color: #777777 !important;
    }
    .comment-for {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 36px;
      background: #f0f0f0;
      border-radius: 2.99363px;
      p {
        padding: 0;
        margin: 0;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10.7771px;
        line-height: 13px;

        color: #777777;
      }
    }
  }
}

.comment-btns {
  margin-left: 5px;
  margin-bottom: 20px;
  display: flex;
  button {
    background-color: #ffffff;
    outline: none;
    border: none;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15.1526px;
    line-height: 18px;
    color: #222222;
    cursor: pointer;
    padding: 0;
    padding-bottom: 5px;
    margin-left: 15px;
    box-sizing: border-box;
    border-bottom: 2px #ffff solid;
  }
}

.profile-active {
  background: #00a075 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  transition: 0.3s ease;
}

.admin-btn:hover {
  background: #00a075 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.comment-active {
  border-bottom: 2px #00a075 solid !important;
}

// change password
.changePassword-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background-color: rgba(241, 241, 241, 0.5);
  border-radius: 5px;
  width: 350px;
  padding: 20px;

  label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
  }

  input {
    padding-left: 10px;
    width: 300px;
    height: 35px;
    border: 1px solid #d7dae0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 5px;
    color: #777777;
    font-size: 12px;
    outline-color: #00a075;
  }
  button {
    margin-top: 10px;
    margin-left: 180px;
  }
}

// my shops

.ownShops {
  display: flex;
  flex-wrap: wrap;
}

.ownShops-container {
  position: relative;
  margin-left: 10px;
  margin-right: 15px;
  margin-bottom: 20px;
  height: 350px;
  background-color: rgba(241, 241, 241, 0.5);
  border-radius: 5px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .ownshop-data {
    margin: 20px;
    display: flex;
    flex-direction: column;
    span {
      color: #222222;
      font-weight: 600;
    }
  }
  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
  button {
    background: #00a075;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    width: 100%;
    height: 50px;
    color: #ffffff;
  }
  .shop-logo {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .edit-store-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #00a075;
    border: #00a075;
    top: 3%;
    right: 5%;
  }
}

// create Store

.createStore-container {
  .common-inputs {
    height: 40px;
    width: 100%;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 2%;
  }

  label {
    text-align: right;
    padding-right: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }

  form {
    background-color: rgba(241, 241, 241, 0.5);
    border-radius: 5px;
    margin: 20px;
    margin-left: 10px;
    padding-right: 30px;
    padding-top: 30px;
    display: grid;
    grid-template-columns: minmax(100px, 250px) minmax(200px, 500px);

    .store-input-div {
      margin-bottom: 30px;
    }

    input {
      outline-color: #00a075;
    }
  }

  .create-btn-store {
    width: 180px;
    height: 45px;
    background: #00a075;
    border: 1px solid #00a075;
    box-sizing: border-box;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    float: right;
  }
}

.profile-topbar {
  background-color: #393230;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  .profile-topbar-links {
    display: flex;
    justify-content: space-between;
    color: #948e8c;
    // border: 2px solid black;

    .profile-user-name {
      position: relative;
      span {
        cursor: pointer;
      }
      .profile-topbar-logout {
        position: absolute;
        background-color: #fff;
        height: 75px;
        width: 130px;
        top: 35px;
        left: -15px;
        border-bottom: 3px solid #00a075;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        ul {
          font-size: 16px;

          padding: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          text-align: center;
          li {
            cursor: pointer;
            color: #333333 !important;
            span {
              color: #333333 !important;
            }
          }
        }
      }
    }
  }
}

/// update store

.update-store-form {
  width: 100%;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
}

.start-end-loop {
  p {
    margin-top: 15px;
  }
}
.start-end-work-container {
  display: grid;
  grid-template-columns: auto auto;
  div {
    margin-right: 10px;
  }
}

@media (max-width: 1430px) {
  .main-profile-container .user-data table {
    min-width: 470px;
  }
}

@media (max-width: 1200px) {
  .main-profile-container .user-avatar {
    min-width: 220px;
    width: 270px;
  }
  .main-profile-container .user-data table {
    min-width: 330px;
  }
  .main-profile-container .user-data {
    margin-left: 20px;
  }
  .main-profile-container .user-data table tr td:nth-child(odd) {
    font-size: 11px;
  }
  .main-profile-container .user-data table tr td:nth-child(even) {
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .main-profile-container .fixed-user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
  }
  .user-profile-contanier .u-profile-sidebar {
    max-width: 300px;
    min-width: 290px;
  }
  .user-profile-contanier .u-profile-page {
    margin: 20px;
    margin-top: 0;
    margin-right: 0;
  }
  .profile-sidebar-title {
    // margin: 15px 20px;
    margin-top: 10px;
  }

  .main-profile-container .user-avatar img {
    width: 150px;
    height: 150px;
  }

  .main-profile-container .user-avatar {
    margin: 0 5px;
    margin-bottom: 15px;
    padding: 10px;
    width: 95%;
  }
  .main-profile-container .user-data table {
    width: 100%;
  }
  .main-profile-container .user-data {
    margin-left: 5px;
    margin-right: 15px;
  }
  .edit-profile-container .edit-user-awatar div p {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .u-profile-sidebar {
    button {
      span {
        display: none;
      }
    }
  }
  .user-profile-contanier .u-profile-sidebar {
    max-width: 55px;
    min-width: 55px;
  }

  .profile-sidebar-title {
    font-size: 20px;
  }
  .main-profile-container .user-data table tr td:nth-child(odd) {
    font-size: 9px;
    line-height: 0px;
  }
  .main-profile-container .user-data table tr td:nth-child(even) {
    font-size: 12px;
    line-height: 0px;
  }
  .main-profile-container .user-data table tr {
    height: 45px;
  }
  .main-profile-container .user-data table {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .edit-profile-container .edit-user-awatar {
    flex-direction: column;
  }
  .edit-profile-form {
    flex-direction: column;
  }
  .edit-profile-form div {
    width: 95%;
  }
  .profile-sidebar-title {
    margin: 10px 10px;
  }
  .main-profile-container .user-avatar {
    margin: 0px 5px;
    margin-bottom: 10px;
  }
  .main-profile-container .user-data {
    margin-left: 5px;
    margin-right: 5px;
  }
  .user-profile-contanier .u-profile-page {
    width: 80%;
  }
  .main-profile-container .user-data table {
    min-width: 295px;
  }
  .edit-profile-container .edit-user-awatar {
    margin: 10px;
    padding: 10px;
  }

  .edit-profile-container .edit-user-awatar div {
    margin-left: 0;
  }
  .edit-profile-container .edit-user-awatar div .edit-user-name {
    margin-top: 5px;
  }
  .changePassword-container button {
    margin-left: 55%;
  }
  .changePassword-container {
    margin-left: 10px;
    width: 95%;
  }
  .changePassword-container input {
    width: 95%;
  }
  .main-profile-container .user-data table tr td:nth-child(even) {
    font-size: 9px;
  }
}

@media (max-width: 480px) {
  .createStore-container {
    form {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      padding-left: 10%;
      label {
        text-align: left;
      }
    }
    .create-btn-store {
      width: 100%;
    }
  }
  .main-profile-container .user-data table tr td:nth-child(even) {
    font-size: 9px;
  }

  .user-profile-contanier .u-profile-page {
    margin: 5px;
  }
  .main-profile-container .user-data table {
    width: 360px;
  }
}

@media (max-width: 420px) {
  .user-profile-contanier {
    flex-direction: column;
  }
  .user-profile-contanier .u-profile-sidebar {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 65px;
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .user-profile-contanier .u-profile-sidebar button {
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-profile-contanier .u-profile-sidebar button .feather-icons {
    margin-right: 0;
  }
  .user-profile-contanier .u-profile-page {
    margin: 0 auto;
    width: 100%;
  }
  .profile-sidebar-title {
    font-size: 14px;
  }
  .main-profile-container .user-data table {
    width: 335px;
  }
}

@media (max-width: 360px) {
  .main-profile-container .user-data table tr td:nth-child(odd) {
    font-size: 10px;
  }
  .main-profile-container .user-data table tr td:nth-child(even) {
    font-size: 10px;
  }
  .main-profile-container .user-data table {
    min-width: 275px;
  }
  .changePassword-container {
    margin: 0 auto;
    width: 94%;
  }
  .changePassword-container button {
    margin-left: 44%;
  }
  .main-profile-container .user-data table {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .main-profile-container .user-data table tr td:nth-child(odd) {
    display: none;
  }
  .main-profile-container .user-data table {
    min-width: 260px;
  }
}

@media (max-width: 280px) {
  .main-profile-container .user-data table {
    min-width: 200px;
  }
  .main-profile-container .user-avatar {
    min-width: 198px;
  }
  .changePassword-container input {
    font-size: 10px;
  }
  .changePassword-container label {
    font-size: 12px;
  }
  .changePassword-container button {
    margin-left: 30%;
  }
  .main-profile-container .user-avatar div p {
    font-size: 10px;
  }
}

//time
.rc-time-picker-panel-combobox {
  display: flex;
}
